.headerPreview {
    height: 72px;
    background: #FFFFFF;
}

.pl-10 {
    padding-left: 10px;
}

.pr-10 {
    padding-right: 10px;
}

.preview-content-header {
    background: #3367CD;
    min-height: 200px;
    padding-left: 15px;
    padding-right: 15px;
}

.preview-title {
    padding-top: 5%;
    padding-bottom: 10px;
    justify-content: center;
}

.preview-subject {
    padding-bottom: 10px;
    justify-content: center;
    >li {
        width: auto;
    }
}

.box {
    background: #ECF0FB;
    /* Grey-8 */
    border: 1px solid #F3F3F3;
    box-sizing: border-box;
    border-radius: 8px;
    width: 170px;
    padding: 10px;
    position: relative;
    top: 75%;
    margin: 10px;
}

.quizz {
    background: #FFFFFF;
    border: 1px solid #EBF0FA;
    box-sizing: border-box;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.02);
    border-radius: 8px;
}

.quizz-btn {
    align-self: center;
    padding: 16px 24px;
    /* Blue NQT */
    background: #3367CD;
    border-radius: 8px;
    color: #FFFFFF;
    cursor: pointer;
}

.questions {
    background: #3367CD;
    height: calc(100vh - 72px);
}

.preview-center {
    top: 50%;
    transform: translate(0, -50%);
    position: absolute;
    max-width: 45%;
}

.question-number {
    font-family: 'CircularStdBook';
    font-size: 24px;
    line-height: 16px;
    display: flex;
    color: #FFFFFF;
}

.question-total {
    font-family: 'CircularStdBook';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    color: #BDBDBD;
}

.question {
    font-family: CircularStd;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 46px;
    display: flex;
    color: #FFFFFF;
}

.type {
    font-family: 'CircularStdBook';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
    padding-top: 30px;
}

.quizz-progress {
    top: 85%;
    position: absolute;
    width: 25%;
}

.answers {
    display: flex;
    justify-content: center;
    align-self: center;
}

.answers-mobile {
    justify-content: center;
    align-self: center;
}

.answer-element {
    background: #FFFFFF;
    border: 1px solid #ECF0FB;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    width: 407px;
    height: 79px;
    padding: 12px;
    margin-bottom: 20px;
}

.answer-number {
    background: #ECF0FB;
    border-radius: 4px;
    width: 25px;
    height: 20px;
    text-align: center;
    align-self: center;
}

.answer-response {
    font-family: 'CircularStdBook';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #28282A;
    margin-left: 10px;
}

.btn-answer {
    top: 85%;
    position: absolute;
    display: flex;
    padding: 20px;
    margin-left: 10%;
    cursor: pointer;
}

.btn-answer-mobile {
    display: flex;
    padding: 20px;
    margin-left: 10%;
    cursor: pointer;
}

.btn-prec {
    background: #3367CD;
    border-radius: 8px;
    display: flex;
    padding: 5px;
    margin: 5px;
    width: 185px;
    height: 48px;
    color: white;
    align-self: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.btn-suiv {
    background: #3367CD;
    border-radius: 8px;
    display: flex;
    padding: 5px;
    margin: 5px;
    width: 185px;
    height: 48px;
    color: white;
    align-self: center;
    justify-content: center;
    align-items: center;
    margin: auto;
    cursor: pointer;
}

.result {
    position: relative;
    top: -20%;
    display: grid;
    text-align: center;
}

.score-success {
    font-family: CircularStd;
    font-size: 64px;
    line-height: 75px;
    color: #3367CD;
}

.score-echec {
    font-family: CircularStd;
    font-size: 64px;
    line-height: 75px;
    color: #ED984F;
}

.totale {
    font-family: CircularStd;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 92px;
    color: #BDBDBD;
}

.score-msg {
    font-family: CircularStd;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #3367CD;
    text-align: center;
}

.assessment {
    padding: 10%;
    background: #E5E5E5;
    height: calc(100vh - 72px);
}

.accordion-header-active {
    background: #3367CD !important;
    border: 1px solid #EBF0FA !important;
    box-sizing: border-box !important;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.02) !important;
    border-radius: 8px !important;
    height: 88px;
    display: flex;
    align-items: center;
    text-align: center;
}

.accordion-header-inactive {
    background: #E5E5E5 !important;
    border: 1px solid #EBF0FA !important;
    box-sizing: border-box !important;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.02) !important;
    border-radius: 8px !important;
    height: 88px;
    display: flex;
    align-items: center;
    text-align: center;
}

.accordion-header-completed {
    background: #FFFFFF !important;
    border: 1px solid #EBF0FA !important;
    box-sizing: border-box !important;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.02) !important;
    border-radius: 8px !important;
    height: 88px;
    display: flex;
    align-items: center;
    text-align: center;
}

.eval-number {
    background: white;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: inline-block;
    color: #3367CD;
    padding: 10px;
}

.eval-title {
    margin: 21px;
    font-family: 'CircularStdBook';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
}

.eval-number-inactive {
    background: #BDBDBD;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: inline-block;
    color: #FFFFFF;
    padding: 10px;
}

.eval-number-completed {
    background: #ECF0FB;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: inline-block;
    color: #3367CD;
    padding: 10px;
}

.eval-title-inactive {
    margin: 21px;
    font-family: 'CircularStdBook';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #BDBDBD;
    opacity: 0.4;
}

.eval-title-completed {
    margin: 21px;
    font-family: 'CircularStdBook';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #3367CD;
}

.eval-progress {
    margin-left: auto;
    display: flex;
}

.eval-completed {
    margin-left: 5px;
    font-family: 'CircularStdBook';
    font-size: 14px;
    line-height: 15px;
    color: #3367CD;
}

.info {
    font-family: CircularStd;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #28282A;
}

.choice {
    display: contents;
}

.choice-element {
    display: flex;
    padding: 15px;
    border-bottom: 1px solid #EBF0FA;
    svg {
        cursor: pointer;
    }
}

.choice-title {
    font-family: 'CircularStdBook';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    color: #28282A;
    margin-left: 10px;
}

.btn-valider {
    background: #3367CD;
    border-radius: 8px;
    display: flex;
    padding: 5px;
    margin: 5px;
    width: 185px;
    height: 48px;
    color: white;
    align-self: center;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-top: 40px;
    cursor: pointer;
}

#pdfviewer {
    width: 85%;
    margin: 0 auto;
    height: 100vh;
}

.pdf-toolbar {
    background: #FFFFFF;
    /* NQT-LightBlue */
    border: 1px solid #ECF0FB;
    box-sizing: border-box;
    display: flex;
}

.navigation-btn {
    display: flex;
    align-self: center;
    justify-content: center;
    cursor: pointer;
}

.page-label {
    span {
        font-family: 'CircularStd';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        /* NQT — Grey -1 */
        color: #BDBDBD;
        padding: 10px;
    }
}

.fullscreen {
    span {
        font-family: 'CircularStd';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: #BDBDBD;
        padding: 10px;
    }
}

.answers-mobile {
    background: #FFFFFF;
    border-top-left-radius: 15%;
    border-top-right-radius: 15%;
    height: 200px;
    top: -13px;
    position: relative;
}

.preview-xls {
    display: flex;
    background: #F9F9F9;
    border-radius: 8px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.zoom-container {
    display: flex;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px;
    margin-top: 10px;
    .zoom-url {
        font-family: CircularStd;
        font-size: 14px;
        line-height: 18px;
        color: #BDBDBD;
    }
    .zoom-title {
        font-family: CircularStd;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        color: #3367CD;
    }
}

.questions-container {
    margin-right: 0 !important;
    margin-left: 0 !important;
    height: calc(100vh - 72px);
}

.note-box {
    margin: 5px;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 90px;
    height: 90px;
    /* NQT—Grey-Bg */
    background: #F9F9F9;
    /* NQT-LightBlue */
    border: 1px solid #ECF0FB;
    box-sizing: border-box;
    border-radius: 10px;
    text-align: center;
    /* NQT-Black */
    color: #28282A;
    font-family: CircularStd;
}

.dropdown-toggle:after {
    align-self: center !important;
}