@import "./scss/variable.scss";
.cardIntro {
  border-width: 1px;
  border-radius: 8px !important;
  box-shadow: 0px 8px 12px #e0e5ee;
  width: 97%;
}
.cardDetails {
  border-width: 1px;
  border-radius: 8px !important;
  border: 1px solid $blue-light-color !important;
  background-color: #ffffff !important;
  padding: 0 !important;
}
.cardDetailsDesc {
  border-width: 1px;
  border-radius: 8px !important;
  border: 1px solid $blue-light-color !important;
  background-color: #ffffff !important;
}
.cardDetailsHighlight{
  border-width: 1px;
  border-radius: 8px !important;
  border: 1px solid $blue-light-color !important;
  background-color: #ffffff !important;
}
.buttonChoice {
  border: 1px solid #ebf0fa;
  border-radius: 8px;
  height: auto;
  min-height: 125px;
  width: 100%;
  padding: 10px;
  align-items: center;
  cursor: pointer;
}

.buttonChoiceFocus {
  border: 1px solid $primary-color;
  border-radius: 8px;
  height: auto;
  min-height: 125px;
  width: 100%;
  padding: 10px;
  align-items: center;
}

.iconOutlined {
  padding: 12px;
  color: $primary-color;
  border: 1px solid #ebf0fa;
  border-radius: 8px;
}

.dottedCard {
  height: 100px;
  width: 100%;
  border: 1px dashed #bdbdbd;
  border-radius: 8px;
  box-sizing: border-box;
}

.closeIcon {
  width: 30px;
  height: 30px;
  background-color: $white-color;
  padding: 7px;
  border-radius: 8px;
  margin-bottom: -30px;
  margin-right: -10px;
  position: relative;
}

.closeIconPrimary {
  width: 20px;
  height: 20px;
  background-color: $white-color;
  border: 1px solid #ebf0fa;
  padding: 2px;
  border-radius: 3px;
}

.ck-editor__editable {
  min-height: 258px;
}

.carded {
  border: 1px solid #ebf0fa;
  width: 100%;
  border-radius: 8px;
  padding: 20px;
}

.Modal {
  position: absolute;
  top: 0px;
  left: 70%;
  right: 0px;
  bottom: 0px;
  background-color: white;
  border: 1px solid #ecf0fb;
  box-sizing: border-box;
  padding: 38px;
  overflow: scroll;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2c2c2c38;
}

.end {
  padding-top: 40%;
}

.tag {
  width: auto;
  padding: 8px;
  margin: 3px;
  border: 1px solid $primary-color;
  background-color: $blue-light-color;
  border-radius: 6.02166px;
  color: $primary-color;
  font-size: 10px;
  font-family: "CircularStdBook";
  line-height: 13px;
}

input::placeholder {
  font-weight: normal;
}

textarea::placeholder {
  font-weight: normal;
}

.select {
  height: 42px;
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #f0f0f0;
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: $black-color;
}

.evalChoice {
  width: 20px;
  height: 20px;
}

.nav-element {
  background: #ffffff;
  border: 1px solid #ecf0fb;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  width: 117px;
  height: 38px;
  align-items: center;
  padding: 10px;
  margin-right: 10px;
  > div {
    font-family: "CircularStdBook";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
  }
  .icon {
    margin-left: auto;
  }
}

.show-more {
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #3367cd;
}

.quizz-msg {
  font-weight: bold !important;
  font-size: 24px !important;
  line-height: 28px !important;
  display: flex !important;
  align-items: center !important;
}

.score-msg-detail {
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #28282a;
}
.feedback {
 max-height: 536px;
 height: auto;
 overflow-y: scroll;
 scrollbar-width: thin;
}
/* width */
.feedback::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.feedback::-webkit-scrollbar-track {
  border-radius: 10px;
}
 
/* Handle */
.feedback::-webkit-scrollbar-thumb {
  background: $primary-color; 
  border-radius: 10px;
}

/* Handle on hover */
.feedback::-webkit-scrollbar-thumb:hover {
  background: $primary-color; 
}