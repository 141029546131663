.categories {
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06);
    border-right: 1px solid #3367CD;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 6.02166px;
    border-bottom-left-radius: 6.02166px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 28px;
    margin-bottom: 10px;
    width: 150px;
}

.subjects {
    background: #ECF0FB;
    border-radius: 0px;
    border-right: 1px solid #3367CD;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 28px;
    margin-bottom: 10px;
    width: 150px;
}

.last {
    border-right: 1px solid #3367CD;
    border-top-right-radius: 6.02166px;
    border-bottom-right-radius: 6.02166px;
}

.content {
    font-family: 'CircularStdBook';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #3367CD;
    padding: 5px;
    text-align: center;
}

.box {
    display: flex;
    margin-right: 15px;
}