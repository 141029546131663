button,
input,
optgroup,
select,
textarea {
  font-family: $sans-serif-family;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $font-weight-normal;
}

a {
  color: $primary-color;
  &:hover,
  &:focus {
    color: $primary-color;
  }
}

h1,
.h1 {
  font-size: $font-size-h1;
  line-height: 1.15;
  margin-bottom: $margin-base-vertical * 2;
  small {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    opacity: 0.8;
  }
}

h2,
.h2 {
  font-size: $font-size-h2;
  margin-bottom: $margin-base-vertical * 2;
}

h3,
.h3 {
  font-size: $font-size-h3;
  margin-bottom: $margin-base-vertical * 2;
  line-height: 1.4em;
}

h4,
.h4 {
  font-size: $font-size-h4;
  line-height: 1.45em;
  margin-top: $margin-base-vertical * 2;
  margin-bottom: $margin-base-vertical;
  & + .category,
  &.title + .category {
    margin-top: -10px;
  }
}

h5,
.h5 {
  font-size: $font-size-h5;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6,
.h6 {
  font-size: $font-size-h6;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
}

p {
  &.description {
    font-size: 1.14em;
  }
}

.title {
  font-weight: $font-weight-bold;
  &.title-up {
    text-transform: uppercase;
    a {
      color: $black-color;
      text-decoration: none;
    }
  }
  & + .category {
    margin-top: -10px;
  }
}

.description,
.card-description,
.footer-big p,
.card .footer .stats {
  color: $dark-gray;
  font-weight: $font-weight-light;
}

.category,
.card-category {
  text-transform: capitalize;
  font-weight: $font-weight-normal;
  color: $dark-gray;
  font-size: $font-size-mini;
}

.card-category {
  font-size: $font-size-h6;
}

.text-primary,
a.text-primary:focus,
a.text-primary:hover {
  color: $brand-primary !important;
}

.text-info,
a.text-info:focus,
a.text-info:hover {
  color: $brand-info !important;
}

.text-success,
a.text-success:focus,
a.text-success:hover {
  color: $brand-success !important;
}

.text-warning,
a.text-warning:focus,
a.text-warning:hover {
  color: $brand-warning !important;
}

.text-danger,
a.text-danger:focus,
a.text-danger:hover {
  color: $brand-danger !important;
}

.text-gray,
a.text-gray:focus,
a.text-gray:hover {
  color: $light-gray !important;
}

.label-grey {
  color: #bdbdbd;
  font-size: 12px;
  font-weight: bold;
}

.label-green {
  color: #50bd89;
  font-size: 12px;
  font-weight: normal;
  font-family: "CircularStdBook";
}
.label-green {
  color: #50bd89;
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  opacity: 1 !important;
}

.label-grey-md-normal {
  color: #bdbdbd;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  font-family: "CircularStdBook";
}

.label-grey-nqt {
  color: $grey-color;
  font-size: 10px;
  line-height: 16px;
  font-weight: 400;
  font-family: "CircularStdBook";
}

.label-grey-trainings {
  color: $grey-color;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "CircularStdBook";
}

.label-grey-trainings-sm {
  color: $grey-color;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  font-family: "CircularStdBook";
}
.opacity-md {
  opacity: 0.8;
}

.label-grey {
  color: #bdbdbd;
  font-size: 16px;
  font-weight: 400;
  font-family: "CircularStdBook";
}

.label-white-trainings {
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: $white-color;
}

.label-black-trainings {
  color: $black-color;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  font-family: "CircularStdBook";
}

.label-grey-trainings {
  color: #8d8d8d;
  font-size: 14px;
  font-weight: 400;
  font-family: "CircularStdBook";
}

.label-grey-nqt-normal {
  color: #bdbdbd;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  font-family: "CircularStdBook";
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.label-black-nqt-normal {
  color: $black-color;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  font-family: "CircularStdBook";
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.show-more-button-label {
  font-family: "CircularStdBook";
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #bdbdbd;
}

.comment-label {
  font-family: "CircularStdbook";
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: $black-color;
}

.comment-date-label {
  font-family: "CircularStdbook";
  font-weight: normal;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  color: #8d8d8d;
}

.label-primary {
  padding-top: 15px;
  color: $primary-color;
  font-size: 12px;
  font-weight: bold;
}

.label-primary-md {
  padding-top: 15px;
  color: $primary-color;
  font-size: 14px;
  font-weight: bold;
}

.label-primary-open-button {
  font-family: "CircularStdBook";
  color: $primary-color;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
}

.label-aqua {
  color: $blue-medium-color;
  font-size: 12px;
  font-weight: bold;
}

.label-primary-normal {
  color: $primary-color;
  font-size: 12px;
  font-family: "CircularStdBook";
  font-weight: normal !important;
  line-height: 10px;
}

.label-grey-normal {
  color: $grey-color;
  font-size: 12px;
  font-family: "CircularStdBook";
  font-weight: normal !important;
  line-height: 18px;
  text-overflow: ellipsis;
}
.label-black-normal {
  padding-top: 15px;
  color: $black-color;
  font-size: 12px;
  font-family: "CircularStdBook";
  font-weight: normal !important;
  line-height: 10px;
}

.label-pink {
  color: #ff66a9;
  font-size: 11px;
  font-family: "CircularStdBook";
  font-weight: normal;
  float: right;
}

.label-primary-xsm {
  color: $primary-color;
  font-size: 11px;
  font-family: "CircularStdBook";
  font-weight: normal;
  float: right;
}

.label-pink {
  color: #ff66a9;
  font-size: 11px;
  font-family: "CircularStdBook";
  font-weight: normal;
  float: right;
}

.nav-link,
.label-black,
.label-black:hover {
  color: #28282a;
  font-size: 12px;
  line-height: 17, 71px;
  font-weight: 400;
  font-family: "CircularStdBook";
}

.nav-link-disabled {
  color: #bdbdbd !important;
  font-size: 12px;
  line-height: 17, 71px;
  font-weight: 400;
  font-family: "CircularStdBook";
}

.nav-link-focus {
  color: $primary-color !important;
  font-size: 12px;
  line-height: 17, 71px;
  font-weight: 400;
  font-family: "CircularStdBook";
}

.label-black-md {
  color: $black-color;
  font-size: 16px;
  font-weight: bold;
}

.label-black-nqt-md {
  color: $black-color;
  font-size: 18px;
  font-weight: bold;
}

.label-orange-sm {
  color: #ed984f;
  font-size: 12px;
  line-height: 16px;
  font-family: "CircularStdBook";
  letter-spacing: 0.04em;
}

.label-orange-lg {
  color: #ed984f;
  font-size: 18px;
  font-weight: bold;
}

.label-orange-md {
  color: #ed984f;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
}

.label-pink-md {
  color: #ff66a9;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
}

.label-blue-md {
  color: #3367cd;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
}

.label-black-nqt-sm {
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $black-color;
}
.remove-style {
  all: unset;
}
.align-style {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 40px;
}

.style-actions {
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 5px;
  border-radius: 5px;
  border-width: 1px;
  border-color: #dee2e6;
  gap:20px;
}
.container-align-items {
  height: 40px;
}
.style-nombre-point{
  padding: 5px;
  display: flex;
  flex-direction: column;
  width: 60px;
  background-color: #f0f0f0;
  border-radius: 5px;
  border-width: 1px;
  border-color: #f0f0f0;
  text-align: center;
  gap:20px;
}

.label-black-nqt {
  font-family: "CircularStd";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: $black-color;
}
.label-black-md-nqt {
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: $black-color;
}
.label-black-for-creator {
  font-family: "CircularStd";
  font-size: 14px;
  line-height: 18px;
  color: $black-color;
  font-weight: 400;
  font-style: normal;
}

.label-primary-nqt-md {
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: $primary-color;
}

.label-grey-nqt-md {
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: $grey-color;
}

.label-grey-nqt-lg {
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #bdbdbd;
}

.label-grey-sm {
  color: $grey-color;
  font-size: 14px;
  font-family: "CircularStdBook";
  font-weight: normal !important;
  line-height: 18px;
  text-overflow: ellipsis;
}

.label-primary-nqt-sm {
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: $primary-color;
  opacity: 1 !important;
}

.label-primary-nqt-mm {
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: $primary-color;
  opacity: 1 !important;
}

.optional {
  color: $grey-color;
  font-family: "CircularStdBook";
  font-size: 12px;
  font-style: italic;
}

.label-primary-nqt-xlg {
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 60px;
  color: $primary-color;
}
.label-primary-nqt-lg {
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
  font-size: 28px;
  color: $primary-color;
}

.label-black-nqt-xmd {
  font-family: "CircularStdBook";
  font-size: 18px;
  line-height: 32px;
  color: $black-color;
}

.label-black-lg {
  color: $black-color;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
}

.label-black-xlg {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 28px;
}

.label-show-more-button {
  font-family: "CircularStdBook";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  text-transform: lowercase;
}

.nav-link-icon {
  padding: 5px;
  margin-right: 10px;
  color: $blue-medium-color;
}

.blockquote {
  border-left: none;
  border: 1px solid $default-color;
  padding: 20px;
  font-size: $font-size-blockquote;
  line-height: 1.8;
  small {
    color: $default-color;
    font-size: $font-size-small;
    text-transform: uppercase;
  }
  &.blockquote-primary {
    border-color: $primary-color;
    color: $primary-color;
    small {
      color: $primary-color;
    }
  }
  &.blockquote-danger {
    border-color: $danger-color;
    color: $danger-color;
    small {
      color: $danger-color;
    }
  }
  &.blockquote-white {
    border-color: $opacity-8;
    color: $white-color;
    small {
      color: $opacity-8;
    }
  }
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

.mtp-6 {
  margin-top: 6%;
}

.pt-80 {
  padding-top: 80px;
}

.p-100 {
  padding: 7%;
}

.pointer {
  cursor: pointer;
}

.label-white-xlg {
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 46px;
  display: flex;
  align-items: center;
  /* NQT-White */
  color: #ffffff;
  max-width: 504px;
  font-family: "CircularStdBook";
}

.label-white-md {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* NQT-White */
  color: #ffffff;
  max-width: 504px;
}
.label-white-sm {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* NQT-White */
  color: #ffffff;
  font-family: "CircularStdBook";
}

.label-white-lg {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 122% */
  /* NQT-White */
  color: #ffffff;
  max-width: 504px;
}

.label-black-md-trainings {
  color: $black-color;
  font-size: 16px;
  line-height: 16px;
  font-family: "CircularStd";
}

.label-grey-md {
  color: #bdbdbd;
  font-size: 16px;
  line-height: 18px;
  font-family: "CircularStdBook";
}

.label-black-xxlg {
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 61px;
  display: flex;
  align-items: center;
  /* NQT-Black */
  color: #28282a;
  max-width: 600px;
}

.label-search {
  padding-left: 30px;
  font-family: "CircularStdBook";
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.04em;
  color: #bdbdbd;
  padding-top: 22px;
}

.label-green-proofreader {
  color: #50bd89;
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  opacity: 1 !important;
}

.label-green-proofreader-sm {  
  color: #50bd89;
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.label-green-proofreader-lg {
  color: #50bd89;
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 45px;
}

.label-pink-contributor {
  color: #FF66A9;
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  opacity: 1 !important;
}

.label-pink-contributor-sm {
  color: #FF66A9;
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.label-pink-contributor-lg {
  color: #FF66A9;
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 45px;
}

.label-blue-creator {
  color: #3367CD;
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  opacity: 1 !important;
}

.label-blue-creator-sm {
  color: #3367CD;
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.label-blue-creator-lg {
  color: #3367CD;
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 45px;
}

.label-orange-animator {
  color: #ED984F;
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  opacity: 1 !important;
}

.label-orange-animator-sm {
  color: #ED984F;
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.label-orange-animator-lg {
  color: #ED984F;
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 45px;
}

.label-light-blue-learner {
  color: #83A6FF;
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  opacity: 1 !important;
}

.label-light-blue-learner-sm {
  color: #83A6FF;
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.label-light-blue-learner-lg {
  color: #83A6FF;
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 45px;
}

.label-black-gamification {  
  color: $black-color;
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.label-grey-gamification {  
  color: #BDBDBD;
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  opacity: 1 !important;
}

.label-grey-gamification-sm {  
  color: #BDBDBD;
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.label-black-nqt-gamification {
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: $black-color;
}
