$primary-color: #3367cd !default;
$black-color: #28282a !default;
$grey-color: #8d8d8d !default;
$white-color: #ffffff !default;
$blue-dark-color: #28276d !default;
$blue-medium-color: #00c2ff !default;
$blue-light-color: #ecf0ff !default;
$link-disabled-color: #666666 !default;
$pink-color: #ff66a9 !default;
$pink-light-color: #fff0f6 !default;
$success-color: #6bd098 !default;
$info-color: #51bcda !default;
$warning-color: #fbc658 !default;
$danger-color: #cc2127 !default;

:export {
  primary-color: $primary-color;
  black-color: $black-color;
  grey-color: $grey-color;
  white-color: $white-color;
  blue-dark-color: $blue-dark-color;
  blue-medium-color: $blue-medium-color;
  blue-light-color: $blue-light-color;
  link-disabled-color: $link-disabled-color;
  pink-color: $pink-color;
  pink-light-color: $pink-light-color;
  success-color: $success-color;
  info-color: $info-color;
  warning-color: $warning-color;
  dander-color: $danger-color;
}
