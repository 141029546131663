@import "./scss/variable.scss";

.catalog-table-header {
  background-color: #ffffff !important;
  border-radius: 10px !important;
  border: 1px solid #ecf0fb !important;
}
.catalog-table-body {
  background-color: #ffffff !important;
  height: 56px;
  border: 1px solid #ecf0fb;
  border-radius: 10px;
}
.iconOutlined {
  padding: 10px;
  color: $primary-color;
  border: 1px solid #ebf0fa;
  border-radius: 4px;
  height: 45px;
}
.textBorder {
  background-color: $blue-light-color;
  height: 20px;
  width: 20px;
  border: 4px;
  color: $primary-color;
}
.tooltip {
  background-color: $primary-color;
  border-radius: 8px;
  color: 'white';
  width:200px;
  height: 47px;
}
