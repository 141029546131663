@import "./scss/variable.scss";
.post__container {
    background: #ffffff;
    /* NQT-LightBlue */
    border: 1px solid #ecf0fb;
    border-radius: 8px;
    width: 60%;
    margin: 0 auto;
}

.post__header {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    /* NQT—Black */
    color: #28282a;
}

.post_header__target {
    background: #f9f9f9;
    border-radius: 23px;
    display: flex;
    justify-content: flex-end;
    width: fit-content;
}

.diffusion_header__target {
    display: flex;
    justify-content: flex-end;
    width: fit-content;
}

.post__body {
    background: #f9f9f9;
    border-radius: 4px;
    width: 100%;
    height: 64px;
    resize: none;
    border: transparent;
}

.body__container {
    position: relative;
}

.mention {
    position: absolute;
    right: 5px;
    top: 43px;
     :hover {
        fill: #3367cd;
    }
}

.emoji {
    position: absolute;
    right: 20px;
    top: 43px;
     :hover {
        fill: #3367cd;
    }
}

.hashtag {
    position: absolute;
    right: 35px;
    top: 43px;
     :hover {
        fill: #3367cd;
    }
}

.action-post {
    padding-right: 10px;
    svg:hover {
        circle {
            fill: #ecf0fb;
        }
        path {
            fill: #3367cd;
        }
    }
}

.emoji-mart-bar {
    display: none;
}

.link-container {
    position: relative;
    margin-top: 16px;
    width: 50%;
    input {
        background: #f9f9f9;
        border-radius: 8px;
        padding-left: 32px;
        border: 0;
        width: 100%;
        height: 33px;
    }
    span {
        position: absolute;
        top: 5px;
        left: 5px;
    }
        .info-link{
            display: block;
            margin: auto;
            margin-left: 6px;
        }
}

.post__body__control {
    margin-top: 10px;
    margin-bottom: 20px;
}

.post__body__input {
    background-color: #fff;
    border-radius: 3px;
    transition: 0.3s ease-in-out;
    padding: 9px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    border: 1px solid #dedede;
    &:focus,
    &:active {
        outline: none;
        border: 1px solid #3fb9de;
    }
    &::placeholder {
        color: #a4b0be;
        font-size: 14px;
    }
}

.post__body__highlighter {
    padding: 10px;
    height: 70px;
}

.post__body__suggestions__list {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 14px;
    max-height: 200px;
    overflow: auto;
}

.post__body__suggestions__item {
    padding: 5px 15px;
    background: #ffffff;
    width: 354px;
    height: 52px;
}

.post__body__suggestions__item--focused {
    background: #ecf0fb;
    border-radius: 8px;
}

.club-filter {
    color: #ffffff;
    font-size: 12px;
    font-weight: normal;
    font-family: "CircularStdBook";
    background-color: $primary-color;
    border-radius: 100px;
    padding: 5px;
}

.counter-club {
    color: $primary-color;
    background-color: white;
    font-size: 11px;
    border-radius: 120px;
    padding: 3px;
}

.label-grey-club {
    color: $grey-color;
    font-size: 11px;
    font-family: "CircularStdBook";
    font-weight: normal !important;
    line-height: 18px;
    text-overflow: ellipsis;
}

.post-text {
    font-size: 12px;
    line-height: 18px;
    color: black;
    font-family: "CircularStdBook";
}

.circle-icon {
    background-color: $blue-light-color;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding-left: 5px;
    padding-top: 1px;
}

.reaction-selected {
    width: auto;
    height: 24px;
    background: #ecf0fb;
    border-radius: 64px;
    padding: 5px;
}

.reactions {
    border-width: 1px;
    border-radius: 48px !important;
    border: 1px solid $blue-light-color !important;
    background-color: #ffffff !important;
}

.card-comment {
    background: #f9f9f9;
    border-radius: 4px;
}

.post-comment {
    background: #f9f9f9;
    border-radius: 4px;
    width: 100%;
    min-height: 34px;
    resize: none;
    border: transparent;
    font-size: 12px;
    line-height: 18px;
    font-family: "CircularStdBook";
    color: black;
}

.post-comment::placeholder {
    font-size: 12px;
    line-height: 18px;
    font-family: "CircularStdBook";
    color: #8d8d8d;
}

.post-comment__control {
    margin-top: 0px;
    margin-bottom: 0px;
}

.post-comment__input {
    background: #f9f9f9;
    border-radius: 4px;
    width: 100%;
    height: 34px;
    resize: none;
    border: transparent;
    font-size: 12px;
    line-height: 18px;
    font-family: "CircularStdBook";
    color: black;
    &::placeholder {
        font-size: 12px;
        line-height: 18px;
        font-family: "CircularStdBook";
        color: #8d8d8d;
    }
}

.post-comment__highlighter {
    padding: 0px;
}

.post-comment__suggestions__list {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 14px;
    max-height: 200px;
    overflow: auto;
}

.post-comment__suggestions__item {
    padding: 5px 15px;
    background: #ffffff;
    width: 354px;
    height: 52px;
}

.post-comment__suggestions__item--focused {
    background: #ecf0fb;
    border-radius: 8px;
}

// .popover-body {
//   margin-left: -20px;
//   height: 320px;
//   margin-top: -20px;
// }
.popover-body {
    padding: 0.5rem;
}

.tweet {
    background: #008ee9;
    border-radius: 26.5361px;
    width: 66px;
    height: 21.77px;
}

.linkedin {
    width: 55px;
    height: 19.78px;
    background: #0274b3;
    border-radius: 1.85393px;
}

#hashtag,
#mention {
    font-size: 12px;
    line-height: 18px;
    color: #0A66C2 !important;
    font-family: "CircularStdBook";
}

.comment-emoji {
    position: absolute;
    right: 27px;
    top: 8px;
     :hover {
        fill: #3367cd;
        color: rgb(51, 103, 205);
    }
}

.comment-send {
    position: absolute;
    right: 5px;
    top: 6px;
}

.comment-send-active {
    position: absolute;
    right: 5px;
    top: 6px;
    color: rgb(51, 103, 205);
    fill: #3367cd;
}