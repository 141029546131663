.audio-player {
    --player-button-width: 3em;
    --space: .5em;
    height: 108px;
}

.icon-container {
    width: 100%;
    height: 100%;
    background-color: #ECF0FB;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 8px ;
}

.audio-icon {
    width: 90%;
    height: 90%;
}

.controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 10px;
}

.player-button {
    cursor: pointer;
    width: 24px;
    height: 24px;
    left: 190px;
    top: 4387px;
    /* NQT-Blue */
    background: #3367CD;
    /* Grey-7 */
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    border-radius: 8px;
}

.timeline {
    -webkit-appearance: none;
    width: calc(100% - (var(--player-button-width) + var(--space)));
    background-repeat: no-repeat;
    margin-right: var(--space);
    height: 8px;
    background-color: #FFFFFF;
    border-radius: 78px;
    background-size: 0% 100%;
    background-image: linear-gradient(#3367CD, #3367CD);
}

.timeline::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
    transition: all .1s;
    background-color: #3367CD;
}

.timeline::-moz-range-thumb {
    -webkit-appearance: none;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
    transition: all .1s;
    background-color: #3367CD;
}

.timeline::-ms-thumb {
    -webkit-appearance: none;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
    transition: all .1s;
    background-color: #3367CD;
}

.timeline:hover::-webkit-slider-thumb {
    opacity: 1;
}

.timeline:hover::-moz-range-thumb {
    opacity: 1;
}

.timeline:hover::-ms-thumb {
    opacity: 1;
}

.timeline::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
}

.timeline::-moz-range-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
}

.timeline::-ms-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
}

.current-time {
    font-family: CircularStd;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */
    display: flex;
    align-items: center;
    /* NQT-Blue */
    color: #3367CD;
    margin-left: 20px;
    margin-right: 5px;
}

.duration {
    font-family: CircularStd;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */
    display: flex;
    align-items: center;
    /* NQT-Blue */
    color: #3367CD;
    margin-left: 5px;
    margin-right: 20px;
}