body {
    color: $black-color;
    font-size: $font-size-base;
    font-family: $sans-serif-family;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.main {
    position: relative;
    background: $white-color;
}

@media (min-width: 992px) and (max-width: 1440px) {
    .first-step-image {
        max-height: 200px;
    }
}


/* Animations */

.nav-pills .nav-link,
.navbar,
.nav-tabs .nav-link,
.sidebar .nav a,
.sidebar .nav a i,
.animation-transition-general,
.tag,
.tag [data-role="remove"],
.animation-transition-general {
    @include transition($general-transition-time, $transition-ease);
}

//transition for dropdown caret
.dropdown-toggle:after,
.bootstrap-switch-label:before,
.caret {
    @include transition($fast-transition-time, $transition-ease);
}

.dropdown-toggle[aria-expanded="true"]:after,
a[data-toggle="collapse"][aria-expanded="true"] .caret,
.card-collapse .card a[data-toggle="collapse"][aria-expanded="true"] i,
.card-collapse .card a[data-toggle="collapse"].expanded i {
    @include rotate-180();
}

.button-bar {
    display: block;
    position: relative;
    width: 22px;
    height: 1px;
    border-radius: 1px;
    background: $white-bg;
    &+.button-bar {
        margin-top: 7px;
    }
    &:nth-child(2) {
        width: 17px;
    }
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
    cursor:pointer;
}
.offline-doc {
    .navbar.navbar-transparent {
        padding-top: 25px;
        border-bottom: none;
        .navbar-minimize {
            display: none;
        }
        .navbar-brand,
        .collapse .navbar-nav .nav-link {
            color: $white-color !important;
        }
    }
    .footer {
        z-index: 3 !important;
    }
    .page-header {
        .container {
            z-index: 3;
        }
        &:after {
            background-color: rgba(0, 0, 0, 0.5);
            content: "";
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 2;
        }
    }
}

.fixed-plugin {
    .dropdown-menu li {
        padding: 2px !important;
    }
}

// badge color
.badge {
    &.badge-default {
        @include badge-color($default-color);
    }
    &.badge-primary {
        @include badge-color($primary-color);
    }
    &.badge-info {
        @include badge-color($info-color);
    }
    &.badge-success {
        @include badge-color($success-color);
    }
    &.badge-warning {
        @include badge-color($warning-color);
    }
    &.badge-danger {
        @include badge-color($danger-color);
    }
    &.badge-neutral {
        @include badge-color($white-color);
        color: inherit;
    }
}

form {
    .form-group {
        margin-bottom: 20px;
        .start {
            text-align: start;
        }
        .label-grey {
            color: #8d8d8d;
            font-size: 12px;
            font-weight: bold;
        }
        .label-primary {
            padding-top: 15px;
            color: #3367cd;
            font-size: 12px;
            font-weight: bold;
        }
        .label-pink {
            color: #ff66a9;
            font-size: 11px;
        }
        .label-black {
            color: #28282a;
            font-size: 12px;
            font-weight: bold;
        }
        .right {
            float: right;
        }
        .left {
            float: left;
        }
        .form-control {
            background-color: #ecf0ff;
            border-color: transparent;
            height: 45px;
            border-radius: 13px;
            font-family: sans-serif;
        }
        .form-icon {
            background-color: #ecf0ff;
            height: 43px;
            margin-top: 1px;
            border-radius: 13px;
            border-color: transparent;
            padding-right: 2px;
        }
        .form-textarea {
            background-color: #ecf0ff;
            border-color: transparent;
            border-radius: 13px;
            font-family: sans-serif;
            padding: 10px;
            min-height: 80px;
            min-width: 230px;
            width: auto;
        }
        .form-check {
            background-color: red;
        }
        .form-select {
            background-color: #ecf0ff;
            border-color: transparent;
            height: auto;
            min-height: 120px;
            border-radius: 10px;
            font-family: sans-serif;
            overflow-y: scroll;
            scroll-padding: 2px;
        }
        .form-select-label-black {
            padding-top: 15px;
            color: #28282a;
            font-size: 14px;
            line-height: 18px;
            background-color: #ffffff;
            border: 1px solid #f0f0f0;
            box-sizing: border-box;
            border-radius: 8px;
            font-family: "CircularStdBook";
        }
        .form-select-choice {
            background-color: #ecf0ff;
            color: #3367cd;
            font-size: 12px;
            font-weight: bold;
            margin-bottom: 25px;
        }
        .form-select::-webkit-scrollbar,
        .form-textarea::-webkit-scrollbar {
            width: 2px;
        }
        .form-select::-webkit-scrollbar-track,
        .form-textarea::-webkit-track {
            background-color: transparent;
        }
        .form-select::-webkit-scrollbar-thumb,
        .form-textarea::-webkit-thumb {
            background-color: transparent;
            border-radius: 20px;
        }
        .form-select:focus {
            outline: none !important;
            box-shadow: none !important;
        }
        .alert {
            background-color: $pink-light-color;
            height: auto;
            border-radius: 9px;
        }
        .app-container {
            width: 500px;
            margin: 30px auto;
        }
        .clear {
            clear: both;
        }
        .details,
        .title {
            text-align: center;
        }
        .error {
            margin-top: 4px;
            color: red;
        }
        .has-error {
            border: 1px dotted red;
        }
        .image-container {
            align-items: flex-start;
            display: flex;
            width: 60%;
            height: 60%;
            float: left;
            margin: 5px 5px 5px 5px;
            text-align: center;
        }
        .preview-container {
            height: 335px;
            width: 100%;
            margin-bottom: 10px;
        }
        .render-preview {
            padding: 5px;
            text-align: left;
            background-color: #ecf0ff;
            border-style: dashed;
            border-width: 1px;
            border-color: #3367cd;
            height: 100%;
            width: 100%;
            border-radius: 5px;
        }
        .placeholder-preview {
            padding: 5px;
            text-align: center;
            background-color: #ecf0ff;
            border-style: dashed;
            border-width: 1px;
            border-color: #3367cd;
            height: 100%;
            width: 100%;
            border-radius: 5px;
        }
        .upload-container {
            cursor: pointer;
            height: 300px;
        }
        .form-calendar {
            background-color: #ecf0ff;
            border-color: transparent !important;
            border-width: 0px !important;
            height: 45px;
            border-radius: 13px;
            font-weight: bold;
            font-size: 12px;
        }
        .calendar {
            background-color: #ecf0ff;
            border-width: 0px;
            border-radius: 20px;
        }
        .react-date-picker__wrapper {
            display: flex;
            flex-grow: 1;
            flex-shrink: 0;
            border-width: 0px;
        }
        .form-control {
            background-color: #ecf0ff;
            border-color: transparent;
            height: 45px;
            border-radius: 13px;
            font-family: sans-serif;
        }
        .form-icon {
            background-color: #ecf0ff;
            height: 43px;
            margin-top: 1px;
            border-radius: 13px;
            border-color: transparent;
            padding-right: 2px;
        }
        .form-textarea {
            background-color: #ecf0ff;
            border-color: transparent;
            border-radius: 13px;
            font-family: sans-serif;
            padding: 10px;
            min-height: 80px;
            min-width: 230px;
            width: auto;
        }
        .form-check {
            background-color: red;
        }
        .form-select {
            background-color: #ecf0ff;
            border-color: transparent;
            height: auto;
            min-height: 120px;
            border-radius: 10px;
            font-family: sans-serif;
            overflow-y: scroll;
            scroll-padding: 2px;
        }
        .form-select-label-black {
            padding-top: 15px;
            background-color: #ecf0ff;
            color: #28282a;
            font-size: 12px;
            font-weight: bold;
        }
        .form-select-choice {
            background-color: #ecf0ff;
            color: #3367cd;
            font-size: 12px;
            font-weight: bold;
            margin-bottom: 25px;
        }
        .form-select::-webkit-scrollbar,
        .form-textarea::-webkit-scrollbar {
            width: 2px;
        }
        .form-select::-webkit-scrollbar-track,
        .form-textarea::-webkit-track {
            background-color: transparent;
        }
        .form-select::-webkit-scrollbar-thumb,
        .form-textarea::-webkit-thumb {
            background-color: transparent;
            border-radius: 20px;
        }
        .form-select:focus {
            outline: none !important;
            box-shadow: none !important;
        }
        .alert {
            background-color: $pink-light-color;
            height: auto;
            border-radius: 9px;
        }
        .app-container {
            width: 500px;
            margin: 30px auto;
        }
        .clear {
            clear: both;
        }
        .details,
        .title {
            text-align: center;
        }
        .error {
            margin-top: 4px;
            color: red;
        }
        .has-error {
            border: 1px dotted red;
        }
        .image-container {
            align-items: flex-start;
            display: flex;
            width: 60%;
            height: 60%;
            float: left;
            margin: 5px 5px 5px 5px;
            text-align: center;
        }
        .preview-container {
            height: 335px;
            width: 100%;
            margin-bottom: 10px;
        }
        .render-preview {
            padding: 5px;
            text-align: left;
            background-color: #ecf0ff;
            border-style: dashed;
            border-width: 1px;
            border-color: #3367cd;
            height: 100%;
            width: 100%;
            border-radius: 5px;
        }
        .placeholder-preview {
            padding: 5px;
            text-align: center;
            background-color: #ecf0ff;
            border-style: dashed;
            border-width: 1px;
            border-color: #3367cd;
            height: 100%;
            width: 100%;
            border-radius: 5px;
        }
        .upload-container {
            cursor: pointer;
            height: 300px;
        }
        .form-calendar {
            background-color: #ecf0ff;
            border-color: transparent !important;
            border-width: 0px !important;
            height: 45px;
            border-radius: 13px;
            font-weight: bold;
            font-size: 12px;
        }
        .calendar {
            background-color: #ecf0ff;
            border-width: 0px;
            border-radius: 20px;
        }
        .react-date-picker__wrapper {
            display: flex;
            flex-grow: 1;
            flex-shrink: 0;
            border-width: 0px;
        }
    }
    .label-grey {
        color: #8d8d8d;
        font-size: 12px;
        font-weight: bold;
    }
    .right {
        float: right !important;
    }
    .left {
        float: left;
    }
    .start {
        text-align: start;
    }
    .form-control {
        background-color: #ecf0ff;
        border-color: transparent;
        height: 45px;
        border-radius: 13px;
        font-family: sans-serif;
    }
    .form-icon {
        background-color: #ecf0ff;
        height: 43px;
        margin-top: 1px;
        border-radius: 13px;
        border-color: transparent;
        padding-right: 2px;
    }
    .form-textarea {
        background-color: #ecf0ff;
        border-color: transparent;
        border-radius: 13px;
        font-family: sans-serif;
        padding: 10px;
        min-height: 80px;
        min-width: 230px;
        width: auto;
    }
    .form-check {
        background-color: red;
    }
    .form-select {
        background-color: #ecf0ff;
        border-color: transparent;
        height: auto;
        min-height: 120px;
        border-radius: 10px;
        font-family: sans-serif;
        overflow-y: scroll;
        scroll-padding: 2px;
    }
    .form-select-label-black {
        padding-top: 15px;
        background-color: #ecf0ff;
        color: #28282a;
        font-size: 12px;
        font-weight: bold;
    }
    .form-select-choice {
        background-color: #ecf0ff;
        color: #3367cd;
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 25px;
    }
    .form-select::-webkit-scrollbar,
    .form-textarea::-webkit-scrollbar {
        width: 2px;
    }
    .form-select::-webkit-scrollbar-track,
    .form-textarea::-webkit-track {
        background-color: transparent;
    }
    .form-select::-webkit-scrollbar-thumb,
    .form-textarea::-webkit-thumb {
        background-color: transparent;
        border-radius: 20px;
    }
    .form-select:focus {
        outline: none !important;
        box-shadow: none !important;
    }
    .alert {
        background-color: $pink-light-color;
        height: auto;
        border-radius: 9px;
    }
    .app-container {
        width: 500px;
        margin: 30px auto;
    }
    .clear {
        clear: both;
    }
    .details,
    .title {
        text-align: center;
    }
    .error {
        margin-top: 4px;
        color: red;
    }
    .has-error {
        border: 1px dotted red;
    }
    .image-container {
        align-items: flex-start;
        display: flex;
        width: 60%;
        height: 60%;
        float: left;
        margin: 5px 5px 5px 5px;
        text-align: center;
    }
    .preview-container {
        height: 335px;
        width: 100%;
        margin-bottom: 10px;
    }
    .render-preview {
        padding: 5px;
        text-align: left;
        background-color: #ecf0ff;
        border-style: dashed;
        border-width: 1px;
        border-color: #3367cd;
        height: 100%;
        width: 100%;
        border-radius: 5px;
    }
    .placeholder-preview {
        padding: 5px;
        text-align: center;
        background-color: #ecf0ff;
        border-style: dashed;
        border-width: 1px;
        border-color: #3367cd;
        height: 100%;
        width: 100%;
        border-radius: 5px;
    }
    .upload-container {
        cursor: pointer;
        height: 300px;
    }
    .form-calendar {
        background-color: #ecf0ff;
        border-color: transparent !important;
        border-width: 0px !important;
        height: 45px;
        border-radius: 13px;
        font-weight: bold;
        font-size: 12px;
    }
}

.first-step-video {
    height: 250px;
    border-radius: 20px;
    width: 100%;
}

.first-step-image {
    height: 250px;
    border-radius: 27px;
    width: 100%;
    -webkit-backface-visibility: hidden;
    -ms-transform: translateZ(0);
    /* IE 9 */
    -webkit-transform: translateZ(0);
    /* Chrome, Safari, Opera */
    transform: translateZ(0);
}

#style-error {
    font-size: 11px;
    font-family: CircularStdBook;
    font-weight: normal;
    float:right;
    margin:0;
    color: rgb(255, 102, 169);
}

.first-step-title {
    font-weight: $font-weight-bold;
    font-style: normal;
    width: auto;
    font-size: 24px;
    line-height: 28px;
    white-space: pre-line;
}

.title-primary {
    color: $primary-color;
    font-weight: $font-weight-bold;
    font-style: normal;
    width: auto;
    font-size: 24px;
    line-height: 28px;
    white-space: pre-line;
}

.center-container {
    justify-content: center;
    align-items: center;
}

.center-col {
    justify-content: center;
    align-items: center;
    display: flex;
}

.avatar {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 15px;
}


/* For pdf */

.react-pdf__Page {
    margin: 0px;
}

.react-pdf__Page__textContent {
    border: 1px solid rgb(233, 230, 230);
    box-shadow: 1px 1px 1px 1px rgb(232, 232, 232);
    border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
    padding: 3px;
}

.react-pdf__Page__canvas {
    margin: 0 auto;
}

.scrollable-pdf {
    height: 100%;
    width: 100%;
    max-height: 500px;
    overflow-y: auto;
}

.thumb-vertical {
    background: #3367cd;
    border-radius: 10px;
    height: 200px !important;
    width: 50% !important;
}

.thumb-vertical-comments-dropdown {
    background: #3367cd;
    border-radius: 10px;
    width: 4px !important;
}

.track-vertical {
    right: 0px;
    top: 0px;
}

.hr-text {
    color: #d1cfcf;
    align-self: center;
}

.hr-text-grey {
    color: #e0e5ee;
    opacity: 0.8 !important;
    align-self: center;
    width: 100%;
}

.pointer {
    cursor: pointer;
}

.done-icon {
    color: #50bd89;
    background-color: #dcf2e7;
    padding: 10px;
    border-radius: 8px;
}

.upload-icon {
    color: $primary-color;
    background-color: #ebf0fa;
    padding: 5px;
    border-radius: 10%;
}

.btn-round-sm {
    border-width: $border-thin;
    border-radius: 8px;
    text-transform: none;
    font-family: "CircularStdBook";
    font-weight: normal;
}

.btn-round-sm-success,
.btn-round-sm-success:hover,
.btn-round-sm-success:focus {
    border-width: $border-thin;
    border-radius: 8px;
    background-color: #50bd89 !important;
    text-transform: none;
    font-family: "CircularStdBook";
    font-weight: normal;
}

.btn-round-sm-secondary,
.btn-round-sm-secondary:hover,
.btn-round-sm-secondary:focus {
    border-width: $border-thin;
    border-radius: 8px;
    background-color: $blue-light-color !important;
    color: $primary-color !important;
    text-transform: none;
    font-family: "CircularStdBook";
    font-weight: normal;
}

.end-position {
    position: absolute;
    width: 100%;
    bottom: 10px;
}

.bg-pink {
    background-color: $pink-color;
}

.bg-pink-light {
    background-color: $pink-light-color;
    width: auto;
    border-radius: 4px;
}

.bg-primary {
    background-color: $primary-color;
}

.bg-blue-light {
    background-color: $blue-light-color;
    border-radius: 4px;
}

.end-position-step {
    position: absolute;
    margin-top: 120px;
}

.error-container {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    background-color: $primary-color;
}

.error-card {
    width: 100%;
    height: 100%;
    padding-top: 4%;
    padding-bottom: 10%;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.error-img {
    width: 250px;
    height: 220px;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-pagination-bullet {
    width: 6px;
    height: 6px;
}

.swiper-pagination-bullet-active {
    opacity: 1;
    background-color: $primary-color;
}

.center-modal {
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
}

.modal-button-container {
    display: flex;
    flex-direction: row;
}

.modal-image-container {
    width: 250px;
    height: 250px;
}

.modal-image {
    display: flex;
    flex: 1;
}

.modal-big-title {
    font-family: "CircularStdBook";
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin: 0 60px 0 60px;
}

.outlined-success {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #50bd8929;
    padding: 10px;
}

.modal-sub-title {
    font-family: "CircularStdBook";
    font-size: 14px;
    text-align: center;
    margin: 10px 60px 10px 60px;
}

.btn-modal-save {
    border-radius: 10px;
    width: 50%;
    text-transform: none;
    background-color: #3367cd;
    margin-left: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 48px;
    p {
        font-family: "CircularStdBook";
        font-size: 12px;
        color: #fff;
        margin: 0;
        font-weight: 100;
    }
}

.btn-modal-save:hover {
    background-color: #3367cd !important;
}

.btn-modal-cancel {
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    width: 50%;
    text-transform: none;
    background-color: #ebf0fa;
    height: 48px;
    margin-right: 10px;
    display: flex;
    p {
        font-family: "CircularStdBook";
        font-size: 12px;
        color: #3367cd;
        margin: 0;
        font-weight: 100;
    }
}

.btn-modal-cancel:hover {
    background-color: #ebf0fa !important;
}

.rounded-borders {
    border-radius: 10px;
}

.table-row {
    background-color: white;
    border-radius: 10px;
    border: 1px solid #ecf0fb;
}

.center-svg {
    margin: 0 auto;
    display: block;
}


/*.filter-icons-top {
  position: absolute;
  top: 30%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}


.filter-icons-div {
    display: inline-block;
    position: relative;
    left: 6px;
    top: 2px;
}

.filter-icons {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}


/* profile page*/

.progress-profile {
    width: 70px;
    height: 70px;
    position: relative;
    left: -50px;
}

.progress-profile>div>div {
    width: 70px !important;
}

.taux {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 8px;
    background: rgba(53, 189, 239, 0.1);
    border-radius: 4px;
    margin-bottom: 10px;
}

.taux div {
    font-size: 12px;
    line-height: 12px;
    /* identical to box height, or 100% */
    text-align: center;
    /* Blue NQT */
    color: #3367cd;
    /* Inside Auto Layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 4px;
}

.line {
    border: 1px solid #ecf0fb;
}

.desc {
    /* NQT-LightBlue */
    background: #ecf0fb;
    /* NQT-Blue */
    border: 0.752708px solid #3367cd;
    box-sizing: border-box;
    border-radius: 6.02166px;
    width: 147px;
    height: 28px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.desc div {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    margin: 4px 4px;
    color: #3367cd;
}

.expertiseTitle {
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #666464;
}

.expertiseInfo {
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */
    display: flex;
    align-items: center;
    /* Black */
    color: #28282a;
    font-family: CircularStdBook;
}

.expertiseList {
    display: flex;
    flex-wrap: wrap;
}

.title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    /* NQT—Black */
    color: #28282a;
    padding-right: 10px;
}

.category {
    height: 18px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    margin-top: 24px;
    /* NQT—Black */
    color: #28282a;
}

.lists {
    /* display: flex; */
    padding-left: 0px;
}

.list {
    display: inline-block;
    margin: 0px 10px;
    width: fit-content;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-style: normal;
    margin-left: 0px;
    color: $grey-color;
    font-size: 12px;
    font-family: "CircularStdBook";
    font-weight: normal;
    line-height: 18px;
    text-overflow: ellipsis;
}

.active {
    color: #3367cd;
    font-weight: 600 !important;
}

.list:hover {
    color: #3367cd;
}

.subject {
    height: 18px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #28282a;
    margin-bottom: 15px;
    font-family: "CircularStdBold";
}

.checked {
    width: 20px;
    height: 20px;
    /* NQT — Grey */
    border: 1px solid #8d8d8d;
    box-sizing: border-box;
    border-radius: 72px;
}

.checked svg {
    margin-bottom: 4px;
    width: 20px;
    height: 8px;
    margin-bottom: 4px;
}

.checkBox {
    margin-left: 10px;
    margin-bottom: 10px;
    font-family: "CircularStdBook";
}

.unchecked svg {
    box-sizing: border-box;
    border-radius: 50%;
}

.unchecked svg:hover {
    background: #3367cd;
    border: 2px solid #3367cd;
}

.avatar-profile {
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 15px;
    max-width: 68px;
}

.avatar-user {
    overflow: hidden;
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.title-avatar {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
}

.title-avatar .name {
    color: #3367cd;
}

.hlabel {
    margin-left: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    font-family: CircularStdBook;
}

.avatarContainer {
    flex: 0 0 auto;
    width: auto !important;
}

.infos {
    display: flex;
    margin-top: 18px;
}

.info {
    display: flex;
    margin-right: 32px;
}

.agenda {
    background: #ffffff;
    /* Grey-7 */
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
    border-radius: 8px;
    width: 54px;
    height: 54px;
    >.calendar {
        text-align: center;
        display: block;
        color: #3367cd;
    }
    >.day {
        font-size: 18px;
        line-height: 21px;
    }
    >.month {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        font-family: "CircularStdBook";
    }
    >.time {
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 15px;
        font-family: "CircularStdBook";
    }
}

.show-more:hover {
    text-decoration: underline;
    cursor: pointer;
}

.todo {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    .name {
        color: #3367cd;
    }
}

.rounded-borders {
    border-radius: 10px;
}

.center-svg {
    margin: 0 auto;
    display: block;
}

.center-svg-rectangle {
    width: 50px;
    height: 50px;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    display: flex;
    align-items: center;
}

.center-svg-circle {
    width: 50px;
    height: 50px;
    width: 100px;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    display: flex;
    align-items: center;
}

.filter-icons-bottom {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
}

.filter-icons-div {
    display: inline-block;
    position: relative;
    left: 6px;
    top: 2px;
}

.filter-icons {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.overflow-Scroll {
    overflow: scroll;
}

.side {
    width: 20%;
    float: left;
    box-sizing: border-box;
}

.popover {
    max-width: 350px;
}

.training-name {
    line-height: 70px;
    text-align: center;
    float: left;
    margin-bottom: 0px;
    height: min-content;
}

.comments-dropdown-menu {
    max-height: 700px;
    width: 500px;
}

.comments-dropdown-menu-scroll {
    max-height: 400px;
    overflow-y: scroll;
}

.hr-style {
    border-top: 1px solid #8d8d8d;
}

.categoriesList {
    display: flex;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.outlined-circle {
    border-radius: 50%;
    color: $primary-color;
    font-size: 12px;
    width: 20px;
    height: 19px;
    font-weight: 500;
    font-family: "CircularStdBook";
    background-color: $blue-light-color;
}

.outlined-primary {
    border-radius: 120px;
    color: $primary-color;
    font-size: 12px;
    width: auto;
    height: 19px;
    font-weight: 500;
    font-family: "CircularStdBook";
    background-color: $blue-light-color;
}

.outlined-grey {
    border-radius: 120px;
    color: $grey-color;
    font-size: 12px;
    width: auto;
    height: 19px;
    font-weight: 500;
    font-family: "CircularStdBook";
    background-color: #8d8d8d1a;
}

.actif-training {
    color: $primary-color;
    font-size: 11px;
    background-color: rgba(53, 189, 239, 0.08);
    border-radius: 4px;
    width: 35px;
    font-family: "CircularStdBook";
}

.inactif-training {
    color: rgba(237, 152, 79, 1);
    font-size: 11px;
    background-color: rgba(255, 153, 0, 0.08);
    border-radius: 4px;
    width: 40px;
}

.end-position-bottom {
    margin-top: 95%;
}

.training-progress-icon {
    width: auto;
    margin-left: 20px;
}

.comments-scrollbar {
    width: 500;
    height: 400;
}

.calendar-box {
    background: #ffffff;
    border: 1px solid #ebf0fa;
    box-sizing: border-box;
    border-radius: 8px;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-button-next {
    background-image: url("../../img/icons/swiperNextButton.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: 100%;
    top: 90%;
    left: 97%;
    cursor: pointer;
    z-index: 11;
}

.swiper-button-prev {
    background-image: url("../../img/icons/swiperPrevButton.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    top: 90%;
    left: 0%;
    cursor: pointer;
    z-index: 11;
}

.swiper-button-prev::after {
    display: none;
}

.swiper-button-next::after {
    display: none;
}

.feature-pagination {
    width: 20px;
    height: 4px;
    border-radius: 9px;
}

.feature-pagination .active {
    background: white;
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin-left: 0px;
    margin-left: 0px;
}

//Progress bar styles
.wrapper-progress-bar {
    border: 1px solid #ecf0fb;
    margin-right: 20px;
    border-radius: 30px;
    width: 70%;
    height: 8px;
}

.container-progress-bar {
    background-color: #ecf0fb;
    border-radius: 30px;
}

.swiper-slide-style {
    background-image: url("../../img/icons/badgeImagebackground.svg");
    background-repeat: no-repeat;
    background-position: right;
}

.input-gamification {
    width: 22%;
    height: 27px;
    padding: 5px 5px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #F9F9F9;
    resize: none;
  }
.outlined-trophy {
    border-radius: 50%;
    width: 33.6px;
    height: 33.6px;
    background-color: $blue-light-color;
    padding: 2px;
    margin-left: -22px;
}

.outlined-trophy-md {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: $blue-light-color;
    padding: 2px;
    margin-left: -22px;
}

.outlined-green {
    max-width: 179px;
    width: auto;
    height: 32px;
    border-radius: 120px;
    background-color: #50bd8929;
}

.outlined-primary-popup {
    max-width: 217px;
    width: auto;
    height: 32px;
    border-radius: 120px;
    background-color: $blue-light-color;
}

.tooltip {
    border: 1px solid #ECF0FB !important;
}
