@import "./scss/variable.scss";

.gamificationModal {
  border-radius: 8px;
  border: 0px solid transparent;
}
.action-icon {
  background-color: #ECF0FB;
  height: 317px;
  border-radius: 8px 50% 50% 8px;

}
