.subjectList {
    background: #FFFFFF;
    height: 52px;
    border-bottom: 1px solid #ECF0FB;
    align-self: center;
    align-content: center;
    padding-top: 16px;
    padding-left: 10px;
}

.subjectList:hover {
    background: #ECF0FB;
    border-radius: 8px;
    cursor: pointer;
    .unchecked svg {
        background: #3367cd;
        border: 2px solid #3367cd;
    }
    svg {
        color: #3367cd;
    }
}

.filter-label {
    width: 90%;
    display: flex;
    .name {
        font-family: "CircularStdBook";
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #28282A;
        padding-right: 10px;
    }
    .number {
        border-radius: 50%;
        width: 25px;
        height: 20px;
        text-align: center;
        align-self: center;
        color: #3367CD;
        padding-top: 6px;
        background: #EBF0FA;
    }
}

.clearAll {
    padding-top: 20px;
    font-family: "CircularStdBook";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */
    text-align: center;
    /* NQT — Grey -1 */
    color: #BDBDBD;
    cursor: pointer;
}

.filter-label-date {
    font-family: 'CircularStdBook';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    margin-left: 10px;
    color: #28282A;
}

.range {
    margin-left: 10px;
    margin-bottom: 10px;
    font-family: "CircularStdBook";
    color: #BDBDBD;
}