.taux {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 8px;
    background: rgba(53, 189, 239, 0.1);
    border-radius: 4px;
}

.taux div {
    font-size: 12px;
    line-height: 12px;
    /* identical to box height, or 100% */
    text-align: center;
    /* Blue NQT */
    color: #3367CD;
    /* Inside Auto Layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 4px;
    font-family: 'CircularStdBook';
}