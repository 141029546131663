@import "./scss/variable.scss";
.formControl {
    width: 100%;
    height: 42px;
    border-radius: 8px;
    padding: 16px;
    border: 1px solid #f0f0f0;
    background-color: $white-color;
}
.formControComment {
    width: 100%;
    height: 42px;
    padding: 16px;
    border: 1px solid #ffff;
}

.formIcon {
    height: 43px;
    margin-top: 1px;
    border-color: transparent;
    margin-left: -22px;
}
.formIconFirst {
    height: 43px;
    margin-top: 1px;
    border-color: transparent;
    margin-left: -35px;
    :hover{
        fill: $primary-color;
    }
}

.formSearchIcon {
    position: absolute;
    margin-left: 10px;
    height: 43px;
    margin-top: 1px;
    border-color: transparent;
}
.formSearchIconEnd {
    position: absolute;
    margin-left: -25px;
    height: 43px;
    margin-top: 1px;
    border-color: transparent;
}

.formTextarea {
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    padding: 10px;
    min-height: 80px;
    width: 100%;
}

.formSelect {
    border: 1px solid #f0f0f0;
    width: 100%;
    margin-top: 3px;
    padding: 15px;
    height: auto;
    min-height: 200px;
    border-radius: 10px;
    font-family: sans-serif;
    overflow-y: scroll;
    scroll-padding: 2px;
}

.formSelectChoice {
    color: $black-color;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
}

.formTextarea::-webkit-scrollbar,
.formSelect::-webkit-scrollbar {
    width: 2px;
}

.formTextarea::-webkit-track .formSelect::-webkit-track {
    background-color: transparent;
}

.formTextarea::-webkit-thumb .formSelect::-webkit-thumb {
    background-color: transparent;
    border-radius: 20px;
}

.renderPreview {
    padding: 5px;
    text-align: left;
    background-color: #ecf0ff;
    border-style: dashed;
    border-width: 1px;
    border-color: #3367cd;
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.placeholderPreview {
    padding: 5px;
    text-align: center;
    background-color: #ecf0ff;
    border-style: dashed;
    border-width: 1px;
    border-color: #3367cd;
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.calendar {
    background-color: $white-color;
    border: 1px solid #ebf0fa;
    box-sizing: border-box;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.02);
    border-width: 0px;
    border-radius: 8px;
    font-weight: normal;
}

.react-date-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border-width: 0px;
    background-color: "transparent";
}

.react-date-picker__inputGroup {
    min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
    flex-grow: 1;
    padding: 0px;
    box-sizing: content-box;
}

.react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
    font-family: "CircularStdBook";
    font-weight: 400;
    color: $black-color;
    font-size: 12px;
}

.react-calendar__tile--active {
    background: $blue-light-color;
    color: $black-color;
    font-family: "CircularStdBook";
    font-weight: 400;
    font-size: 12px;
}

.react-date-picker__inputGroup {
    position: absolute;
    color: #28282a;
    font-size: 12px;
    line-height: 17, 71px;
    font-weight: 400;
    font-family: "CircularStdBook";
    min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    font-family: "CircularStdBook";
    font-weight: 400;
    color: #bdbdbd;
    font-size: 12px;
    text-transform: none;
}

.react-calendar__navigation button {
    min-width: 44px;
    background: none;
    color: $primary-color;
    font-family: "CircularStdBook";
    font-weight: 600;
}
