.desc {
    /* NQT-LightBlue */
    background: #ECF0FB;
    /* NQT-Blue */
    border: 0.752708px solid #3367CD;
    box-sizing: border-box;
    border-radius: 6.02166px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 28px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 6.02166px;
    width: fit-content;
    padding-left: 3px;
    padding-right: 3px;
}

.descDiv {
    padding: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    color: #3367CD;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: 'CircularStdBook';
}

.descNoBorderDiv {
    padding: 10px;
    font-weight: 750;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #3367CD;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: 'CircularStdBook';
}

.descNoBorder {
    background: #ECF0FB;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 6.02166px;
    height: 28px;
    margin-right: 10px;
    margin-bottom: 10px;
    width: fit-content;
}