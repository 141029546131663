@import "./scss/variable.scss";

.navLink {
  color: $grey-color;
  font-size: 13px;
  line-height: 17, 71px;
  font-weight: 400;
  font-family: "CircularStdBook";
  opacity: 0.99;
  text-decoration: none;
}
.navLinkIcon {
  color: $grey-color;
  padding: 5px;
  margin-right: 10px;
}
.navLink:focus, .navLinkIcon:focus {
    color: $primary-color;
}
.avatarHome {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
}
.avatarHomeSm {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
}
